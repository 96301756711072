#error-msg {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    
}

.error {
    margin: auto 50px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 50vw;
    color: white;
    justify-content: center;
}