#call-us {
    max-height: 65vh;
    height: 100vh;
}

#call-now-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
}



.call-now-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

#call-us-btn {
    border: 2px solid white;
    max-width: fit-content;
    max-height: fit-content;
    border-radius: 15px;
    margin: auto;

    display: inline-flex;
    flex-direction: row;
    padding: 0.30rem;
}

#call-us-btn img {
    max-height: 30px;
}

.call-now-container a,
.call-now-container p a {
    text-decoration: none;
}

#section-common a,
.call-now-container a:visited,
.call-now-container a:active,
.call-now-container p a:visited,
.call-now-container p a:active {
    text-decoration: none;
    color: white;
}

.call-email-container {
    display: inline-flex;
    flex-direction: row;
    gap: 10rem;
    align-items: center;
}

.call-email-container p a,
.call-email-container p a:visited,
.call-email-container p a:link,
.call-email-container p a:active {
    color: white;
}


@media screen and (max-width:1080px) {

    #about-us {
        padding: 30px;
    }

    #call-us-btn img {
        max-height: 32px;
    }

    .call-now-container {
        gap: 25px;
    }

}

@media screen and (max-width:786px) {
    #call-us {
        max-height: 40vh;
        height: 100vh;
    }
    

    #about-us {
        padding: 30px;
    }

    #call-us-btn img {
        max-height: 25px;
    }

    .call-now-container {
        gap: 20px;
    }

    .call-email-container {

        flex-direction: column;
        gap: 10px;

    }

}



@media screen and (max-width:480px) {

    #about-us {
        padding: 10px;
    }

    #call-us-btn img {
        max-height: 20px;
    }

    .call-now-container {
        gap: 15px;
    }

}
