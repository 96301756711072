#services-main {
    max-height: calc(screen-40vw);
}

#services-content {
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 2rem;
}

.section-container {
    margin-left: 50px;
    margin-right: 50px;
    max-width: 100vw;
    display: grid;
    padding-top: 3rem;
    padding-bottom: 3rem;
}


.services-half {
    max-width: 45vw;
    display: grid;
    gap: 20px;
}

.services-half p {
    font-size: 20px;
    line-height: 30px;
}

.services-half h2::selection,
.services-half p::selection {
    color: black;
}

.services-img,
.services-half {
    max-width: 45vw;
}


.services-img img {
    max-width: 45vw;
    border-radius: 3.5rem 0.2rem;
    box-shadow: 0px 0px 10px 2px white;
}

.services-img img:hover {
    box-shadow: 0px 0px 20px 2px white;
    translate: 2px;
}


@media screen and (max-width:768px) {
    #services-content {
        max-width: 100vw;
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 1.5rem;
    }

    .section-container {
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .services-half,
    .services-img {
        max-width: 100vw;
        gap: 15px;
        margin: auto;
    }

    .services-img img {
        max-width: 65vw;
    }

    .services-half p {
        font-size: 16px;
        text-align: center;
        line-height: 25px;
    }

}

@media screen and (max-width:480px) {
    #services-content {
        gap: 1rem;
    }

    .section-container {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .services-half {
        max-width: 100vw;
        gap: 10px;
        margin: auto;
    }

    .services-half p {
        font-size: 14px;
        text-align: center;
        line-height: 20px;
    }
}

/*SERVICES PAGE GRID CONTAINER*/
.grid-container {
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
}

.service-grid-items {
    max-width: 100vw;
    padding: 2.8rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    align-items: center;
}

.grid-items {
    max-width: 300px;
    height: fit-content;
    display: grid;

    gap: 0.5rem;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid gray;
}

.grid-items:hover {
    box-shadow: 0px 0px 10px 5px gray;
    translate: 5px;
}

.grid-items .service-img {
    display: flex;
    justify-content: center;
}

.grid-items .service-img img {
    max-width: 250px;
    /* Adjust the 20px value as needed */
}

@media screen and (max-width:1080px) {
    .service-grid-items {
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
        padding: 1rem;
    }


}

@media screen and (max-width:720px) {
    .service-grid-items {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0.5rem;
    }

}

@media screen and (max-width:480px) {
    /* General Styles */

    .section-container {
        margin-left: 5vw;
        /* Adjust margin for smaller screens */
        margin-right: 5vw;
        /* Adjust margin for smaller screens */
        max-width: 90vw;
        /* Adjust max-width for smaller screens */
        display: grid;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .services-half,
    .services-img {
        width: 90%;
        /* Adjust width for smaller screens */
        max-width: none;
        /* Remove max-width for better flexibility */
        margin: auto;
    }

    /* Services Main Section */

    #services-main {
        max-height: calc(100vh - 100px);
        /* Adjust max-height for smaller screens and header height */
    }

    .services-img img {
        max-width: 100%;
        /* Ensure images don't exceed container width */
        border-radius: 3.5rem 0.2rem;
        box-shadow: 0px 0px 10px 2px white;
    }

    .services-img img:hover {
        box-shadow: 0px 0px 20px 2px white;
        translate: 2px;
    }

    /* Services Content Section */

    #services-content {
        max-width: 90vw;
        /* Adjust max-width for smaller screens */
        display: grid;
        grid-template-columns: 1fr;
        /* Change to single column layout for smaller screens */
        justify-content: center;
        gap: 1.5rem;
        /* Adjust gap for smaller screens */
    }

    .services-half p {
        font-size: 16px;
        /* Reduce font size for smaller screens */
        text-align: center;
        line-height: 25px;
    }

    /* Service Grid Items */

    .service-grid-items {
        max-width: 90vw;
        /* Adjust max-width for smaller screens */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        display: grid;
        grid-template-columns: 1fr;
        /* Change to single column layout for smaller screens */
        gap: 2rem;
        /* Adjust gap for smaller screens */
        align-items: center;
    }

    .grid-items {
        width: 100%;
        /* Ensure items take full width on smaller screens */
        height: fit-content;
        display: grid;
        gap: 1rem;
        /* Adjust gap for smaller screens */
        padding: 1rem;
        border-radius: 20px;
        border: 1px solid gray;
    }

    .grid-items .service-img img {
        max-width: 100%;
        /* Ensure images don't exceed container width */
    }

}